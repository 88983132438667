<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

  <mat-card class="box">
    <mat-card-header>
      <mat-card-title> {{this.resetPasswordComponentItems.mtee_app_rp_header}}</mat-card-title>
    </mat-card-header>

    <form class="formReset" id="formReset" [formGroup]="formReset">
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input type="password" id="password" matInput placeholder="{{this.resetPasswordComponentItems.mtee_app_rp_new}}" formControlName="password">
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input type="password" id="cnfpassword" matInput placeholder="{{this.resetPasswordComponentItems.mtee_app_rp_confirm}}" formControlName="cnfpassword">
        </mat-form-field>
      </mat-card-content>
      <mat-error *ngIf="formReset.controls['password'].hasError('minlength')">
        {{getFormErrorTextPassword()}}
      </mat-error>
      <mat-error *ngIf="formReset.controls['cnfpassword'].hasError('minlength')">
        {{getFormErrorTextPassword()}}
      </mat-error>
      <button mat-stroked-button color="primary" class="btn-block" (click) = "resetPassword()" [disabled]="!formReset.valid">{{this.resetPasswordComponentItems.mtee_app_rp_save_btn}}</button>
    </form>
  </mat-card>

</div>
