import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {map, catchError, tap, retry} from 'rxjs/operators';
const headers = new HttpHeaders();
headers.set('Content-Type', 'application/json; charset=utf-8');
headers.set('Accept', 'application/json');

// const endpoint = 'http://127.0.0.1:9000/';

const endpoint = 'https://mittaristo.lut.fi/pbp/';
const endpoint2 = 'https://ysimittari.lut.fi/pbp/';



@Injectable({
  providedIn: 'root'
})

export class WebserviceCallerService {

  constructor(private http: HttpClient) { }

   // Http Options
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json'
        })
      };


  private extractData(res: Response) {
  const body = res['result'];
  // console.log('Inside extractData in webservice caller');
  return body || { };
  }

 public getAllLanguage(): Observable<any> {
    // console.log('Inside getAllLanguage in webservice caller');
    return this.http.get(endpoint + 'getAllLanguage').pipe(map(this.extractData));
 }
 //
 public getAllLanguageRefreshed(): Observable<any> {
    // console.log('Inside getAllLanguage in webservice caller');
    return this.http.get(endpoint + 'getAllLanguage?reload=true').pipe(map(this.extractData));
 }
 //
 //
 public getErrorList(): Observable<any> {
    // console.log('Inside getErrorList in webservice caller');
    return this.http.get(endpoint + 'getErrorList').pipe(map(this.extractData));
 }
 //
 public getErrorListRefreshed(): Observable<any> {
    // console.log('Inside getErrorList in webservice caller');
    return this.http.get(endpoint + 'getErrorList?reload=true').pipe(map(this.extractData));
 }
 //
 public getSuccessMessageList(): Observable<any> {
    // console.log('Inside getSuccessMessageList in webservice caller');
    return this.http.get(endpoint + 'getSuccessMessageList').pipe(map(this.extractData));
 }
 //
 public getSuccessMessageListRefreshed(): Observable<any> {
    // console.log('Inside getSuccessMessageList in webservice caller');
    return this.http.get(endpoint + 'getSuccessMessageList?reload=true').pipe(map(this.extractData));
 }
 //
 public getCountyList(): Observable<any> {
    // console.log('Inside getCountyList in webservice caller');
    return this.http.get(endpoint + 'getCountryList').pipe(map(this.extractData));
 }
 //
 public getCountyListRefreshed(): Observable<any> {
    // console.log('Inside getCountyList in webservice caller');
    return this.http.get(endpoint + 'getCountryList?reload=true').pipe(map(this.extractData));
 }
 //
 public getYsimittariCountyListRefreshed(): Observable<any> {
    // console.log('Inside getCountyList in webservice caller');
    return this.http.get(endpoint2 + 'getCountryList?reload=true').pipe(map(this.extractData));
 }
 //
 public getAllStaticContent(): Observable<any> {
    // console.log('Inside getAllStaticContent in webservice caller');
    return this.http.get(endpoint + 'getAllStaticContent').pipe(map(this.extractData));
 }
 //
 public getAllStaticContentRefreshed(): Observable<any> {
    // console.log('Inside getAllStaticContent in webservice caller');
    return this.http.get(endpoint + 'getAllStaticContent?reload=true').pipe(map(this.extractData));
 }
 //
 public getQuestionByTheme(): Observable<any> {
    // console.log('Inside getQuestionByTheme in webservice caller');
    return this.http.get(endpoint + 'getQuestions').pipe(map(this.extractData));
 }
 //
 public getQuestionByThemeRefreshed(): Observable<any> {
    // console.log('Inside getQuestionByTheme in webservice caller');
    return this.http.get(endpoint + 'getQuestions?reload=true').pipe(map(this.extractData));
 }
 //
 //
 public getProvienceList(): Observable<any> {
    // console.log('Inside getProvienceList in webservice caller');
    return this.http.get(endpoint + 'getProvienceList').pipe(map(this.extractData));
 }
 // get list of municipalies
 public getMunicipalityList(): Observable<any> {
  // console.log('Inside getMunicipalityList in webservice caller');
  return this.http.get(endpoint + 'getMunicipalityList').pipe(map(this.extractData));
 }
 //
 public getMunicipalityListRefreshed(): Observable<any> {
  // console.log('Inside getMunicipalityList in webservice caller');
  return this.http.get(endpoint + 'getMunicipalityList?reload=true').pipe(map(this.extractData));
 }
 //
 public getYsimittariMunicipalityListRefreshed(): Observable<any> {
  // console.log('Inside getMunicipalityList in webservice caller');
  return this.http.get(endpoint2 + 'getMunicipalityList?reload=true').pipe(map(this.extractData));
 }
 //
 public searchByMunicipalityCode(requestBody): Observable<any> {
    // console.log('Inside searchByMunicipalityCode in webservice caller with requestBody as below : ');
    // console.log(requestBody);
    return this.http.post<any>(endpoint + 'searchByMunicipalityCode', JSON.stringify(requestBody)).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)),
    catchError(this.handleError<any>('searchByMunicipalityCode')));
 }
 //
 //
 public updateLocationDetails(requestBody): Observable<any> {
    // console.log('Inside updateLocationDetails in webservice caller with requestBody as below : ');
    // console.log(requestBody);
    return this.http.post<any>(endpoint + 'updateLocationDetails', JSON.stringify(requestBody)).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)),
    catchError(this.handleError<any>('updateLocationDetails')));
  }
  //
 public deleteLocationDetails(requestBody): Observable<any> {
    // console.log('Inside deleteLocationDetails in webservice caller with requestBody as below : ');
    // console.log(requestBody);
    return this.http.post<any>(endpoint + 'deleteLocationDetails', JSON.stringify(requestBody)).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)),
    catchError(this.handleError<any>('deleteLocationDetails')));
  }
  //
 public saveUserAccessDetails(requestBody): Observable<any> {
    // console.log('Inside saveUserAccessDetails in webservice caller with requestBody as below : ');
    // console.log(requestBody);
    return this.http.post<any>(endpoint + 'saveUserAccessDetails', JSON.stringify(requestBody)).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)),
    catchError(this.handleError<any>('saveUserAccessDetails')));
  }
  //
 public getUserAccessDetails(): Observable<any> {
  // console.log('Inside getUserAccessDetails in webservice caller');
  return this.http.get(endpoint + 'getUserAccessDetails').pipe(map(this.extractData));
  }
//
public getAllSchoolNames(request: {code: any; }): Observable<any> {
  // console.log('Inside getAllSchoolNames in webservice caller');
  return this.http.get(endpoint + 'getAllSchoolNames?code=' + request.code).pipe(map(this.extractData));
}
//
 public getQuestionThemeName(): Observable<any> {
    // console.log('Inside getQuestionThemeName in webservice caller: ');
    return this.http.get(endpoint + 'getQuestionThemeName').pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((response) => console.log(`Fetching theme names`)),
    catchError(this.handleError<any>('getQuestionThemeName'))
  );
  }
  //
  public getQuestionThemeNameRefreshed(): Observable<any> {
    // console.log('Inside getQuestionThemeName in webservice caller: ');
    return this.http.get(endpoint + 'getQuestionThemeName?reload=true').pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((response) => console.log(`Fetching theme names`)),
    catchError(this.handleError<any>('getQuestionThemeName'))
  );
  }
  //
 public saveUserResponse(response): Observable<any> {
    // console.log('Inside saveUserResponse in webservice caller with response as below : ');
    // console.log(response);
    return this.http.post<any>(endpoint + 'saveUserResponse', JSON.stringify(response)).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((response) => console.log(`added new response w/ time=${response}`)),
    catchError(this.handleError<any>('saveUserResponse')));
  }
  //
 public registerUser(requestBody): Observable<any> {
    // console.log('Inside registerUser in webservice caller with requestBody as below : ');
    // console.log(requestBody);
    return this.http.post<any>(endpoint + 'registerUser', JSON.stringify(requestBody)).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)),
    catchError(this.handleError<any>('registerUser')));
  }
  //
  public loginUser(requestBody): Observable<any> {
    // console.log('Inside loginUser in webservice caller with requestBody as below : ');
    // console.log(requestBody);
    return this.http.post<any>(endpoint + 'validateUser', JSON.stringify(requestBody)).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)),
    catchError(this.handleError<any>('validateUser')));
  }
  //
  public getFeedbackParameters(): Observable<any> {
    // console.log('Inside getFeedbackParameters in webservice caller: ');
    return this.http.get(endpoint + 'getFeedbackParameters').pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((response) => console.log(`Fetching feedback parameters`)),
    catchError(this.handleError<any>('getFeedbackParameters'))
  );
  }
  //
  public getAllResponseOfUser(requestBody1: { username: any }, requestBody2: { selectedDate: any }): Observable<any> {
    // console.log('Inside getAllResponseOfUser in webservice caller: ');
    return this.http.get(endpoint + 'getAllResponseOfUser?user=' + requestBody1.username
      + '&selectedDate=' + requestBody2.selectedDate ).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((response) => console.log(`Fetching all response for user`)),
    catchError(this.handleError<any>('getAllResponseOfUser'))
  );
  }
  //
  public getAllDatesForUser(requestBody: { username: any }): Observable<any> {
    // console.log('Inside getAllDatesForUser in webservice caller: ');
    return this.http.get(endpoint + 'getAllDatesForUser?user=' + requestBody.username).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((response) => console.log(`Fetching all response dates for user`)),
    catchError(this.handleError<any>('getAllDatesForUser'))
  );
  }
  //
  //
  recoverUser(requestBody): Observable<any> {
    // console.log('Inside recoverUser in webservice caller: ');
    // console.log(requestBody);
    return this.http.post<any>(endpoint + 'validateAccount', JSON.stringify(requestBody)).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)),
    catchError(this.handleError<any>('validateAccount')));
  }
  //
  resetPassword(requestBody): Observable<any> {
    // console.log('Inside resetPassword in webservice caller: ');
    // console.log(requestBody);
    return this.http.post<any>(endpoint + '/resetPassword', JSON.stringify(requestBody)).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)),
    catchError(this.handleError<any>('resetPassword')));
  }
  //
  updatePassword(requestBody): Observable<any> {
  // console.log('Inside updatePassword in webservice caller: ');
  // console.log(requestBody);
  return this.http.post<any>(endpoint + 'updatepassword', JSON.stringify(requestBody)).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)),
    catchError(this.handleError<any>('updatepassword')));
  }
  //
  //
  public verifyReportKey(request: {key: any; }): Observable<any> {
    return this.http.get(endpoint + 'verifyReportKey?reportKey=' + request.key).pipe(map(this.extractData));
  }
  //
  getDownloadData(useRequest: { startDate: Date;
  endDate: Date;
  report_key: any;
   mtee_campaign_code: string}): Observable<any> {
    // console.log('Inside API Service for downloadReportBetweenDates');
    const isoStartDate = new Date(useRequest.startDate).toISOString();
    const isoEndDate = new Date(useRequest.endDate).toISOString();
    return this.http.get(endpoint
      + 'downloadReportBetweenDates?startDate=' + isoStartDate
      + '&endDate=' + isoEndDate
      + '&report_key=' + useRequest.report_key
      + '&mtee_campaign_code=' + useRequest.mtee_campaign_code, { responseType: 'blob' as 'json'}).pipe(
      retry(1), catchError(this.handleError<any>('downloadReportBetweenDates')));
  }
  //
 public createCampaign(requestBody): Observable<any> {
    return this.http.post<any>(endpoint + 'createCampaign', JSON.stringify(requestBody)).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)),
    catchError(this.handleError<any>('createCampaign')));
  }
  //
 public searchCampaign(requestBody): Observable<any> {
    return this.http.post<any>(endpoint + 'searchCampaign', JSON.stringify(requestBody)).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)),
    catchError(this.handleError<any>('searchCampaign')));
  }
  //
 public editCampaign(requestBody): Observable<any> {
    return this.http.post<any>(endpoint + 'editCampaign', JSON.stringify(requestBody)).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((requestBody) => console.log(`added new response w/ time=${requestBody}`)),
    catchError(this.handleError<any>('editCampaign')));
  }
  //
  //
  //
  private handleError<T>(operation = 'operation', result?: T) {
    // console.log('Inside handleError in webservice caller');
    return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}
}
