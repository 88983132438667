import { Component, OnInit, VERSION, ViewChildren, AfterViewInit, ElementRef, QueryList } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';
import { WebserviceCallerService } from '../webservice-caller.service';
import { DataShareService } from '../data-share.service';
import {ActivatedRoute, Router} from '@angular/router';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import {CookieService} from 'ngx-cookie-service';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioChange } from '@angular/material/radio';

export interface StepType {
  label: string;
  fields: FormlyFieldConfig[];
}
interface Municipality {
  name_municipality: string;
  code_municipality: number;
  name_provience: string;
  code_provience: number;
}
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  @ViewChildren('checkboxes1', { read: ElementRef}) private checkboxes1: QueryList<ElementRef>;
  @ViewChildren('checkboxes2', { read: ElementRef}) private checkboxes2: QueryList<ElementRef>;
  // @ViewChild('checkboxes', {static: false, read: ElementRef}) private checkboxes: ElementRef;
  //
  public saveCampaignForm: FormGroup;
  public saveCampaignCodeRequest = {
    mtee_campaign_code: undefined
  };

  municipalities1: any = [];
  municipalityList1: any = {};
  municipalityListShow1: any = [];
  selectedMunicipality1: Municipality;
  selectedMunicipalityToSave: any = [];


  //
  showSchoolDropdown = false;
  schoolNames: any = [];
  selectedSchoolToSave: any = [];
  //


  private  requestBody = {
    code: undefined
  };
  //
  isSchoolRequired = false;
  activedStep = 0;
  IsChecked = false;
  answerModelCheckbox1: any = [];
  answerModelCheckbox2: any = [];

  //
  SpaAnswer: any = [];
  SpbAnswer: any = [];

  questionComponentItems: any = {};
  userLoginDetails: any = {};
  countyList: any = [];
  selectedCounty = '';
  private ngVersion: string = VERSION.full;
  public selectedLanguage: string;
  private questionListByTheme: any = [];
  public ListByThemeName: any = [];
  private  firstQuestionPage = '';
  private  prevQuestionPage = '';
  private  nowQuestionPage = '';
  private  nextQuestionPage = '';
  private  lastQuestionPage = '';
  private  questionListSelectedTheme: any = [];
  public  listTheme: any = [];
  private  userResponse = {
    end_time: undefined,
    mtee_user_response: undefined,
    start_time: undefined,
    mtee_user_language: '',
    mtee_user_yob: undefined,
    mtee_user_gender: undefined,
    mtee_user_id: undefined,
    mtee_campaign_code: ' '
  };
  provienceList: any = [];
  provienceRecord: any = [];
  selectedProvience: any;
  selectedProvienceCode: any;
  selectedProvienceNameAndCode: any;
  selectedMunicipalityCode: any;
  selectedMunicipalityNameAndCode: any;


  // Only required when not passing the id in methods

  answerModel = {
    SC01: undefined,
    SC02: undefined,
    SC03: undefined,
    SC04: undefined,
    SC05: undefined,
    SC06: undefined,
    SC07: undefined,
    SC08: undefined,
    SC09: undefined,
    SC10: undefined,
    SC11: undefined,
    SC12: undefined,
    SC13: undefined,
    SC14: undefined,
    SC15: undefined,

    SPA: undefined, // From ysimittari
    SPB: undefined // From ysimittari

  };
  scaleQuestionsHandled = false;
  totalQuestions = 0;
  progress = 0;
  totalAnswered = 0;
  pageQuestionLength = 0;
  progressBarValue = 0;
  questionsInPage: any = [];
  questionsAnswered: any = [];

  scaleSize = 30;
  closeResult: string;
  hasCampaignCode = false;
  verifiedCampaign = false;
  // tslint:disable-next-line:variable-name
  updateSetting(event, mtee_question_id, questionType, indexPage) {
    this.scaleSize = event.value;
    this.answeredQuestion(event, mtee_question_id, this.scaleSize, questionType, indexPage);
    // console.log('scale value registered: ' + this.scaleSize);
  }
  //
  // tslint:disable-next-line:variable-name
  onSelectionCounty(county, mtee_question_id, questionType, indexPage) {
    // console.log('----counrty--:', county.value);
    this.selectedCounty = county.value;
    this.answeredQuestion(event, mtee_question_id, this.selectedCounty, questionType, indexPage);
  }
  prevStep(step) {
    this.activedStep = step - 1;
  }

  nextStep(step) {
    this.activedStep = step + 1;
    this.totalAnswered = this.pageQuestionLength;
    this.pageQuestionLength = 0;
    // console.log(this.answerModel);
    // console.log('Page submitted');
    // console.log(this.totalAnswered);
  }

  submit() {
     this.userResponse.mtee_user_id = this.userLoginDetails.mtee_user_id;
     this.userResponse.mtee_user_yob = this.userLoginDetails.mtee_user_yob;
     this.userResponse.mtee_user_gender = this.userLoginDetails.mtee_user_gender;
     this.dataBus.currentLanguage.subscribe(language => this.selectedLanguage = language);
     this.userResponse.mtee_user_response = this.answerModel;
     this.userResponse.end_time = new Date();
     this.dataBus.currentLanguage.subscribe(language => this.selectedLanguage = language);
     this.userResponse.mtee_user_language = this.selectedLanguage;
     // console.log(this.userResponse);
     this.saveUserResponse();
  }



  constructor(public rest: WebserviceCallerService,
              private route: ActivatedRoute,
              private router: Router,
              private dataBus: DataShareService,
              private formBuilder: FormBuilder,
              public cookieService: CookieService) {
                this.getMunicipalityList();
                this.saveCampaignForm = this.formBuilder.group({
                          mtee_campaign_code: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(30)])]
                          });

               }

  ngOnInit() {
    this.dataBus.questionPageStatic.subscribe(message => this.questionComponentItems = message);
    this.dataBus.currentLanguage.subscribe(language => this.selectedLanguage = language);
    this.dataBus.userLoginResult.subscribe(message => this.userLoginDetails = message);
    this.cookieService.set('showFeedback', 'Y', undefined, '/', undefined, false);
    // console.log(this.selectedLanguage);
    this.getThemeNames();
    this.getQuestionByTheme();
    this.getProvienceList();
    this.getCountyList();
  }

  getThemeNames() {
    // console.log('Inside getThemeNames in question component caller');
    this.ListByThemeName = [];
    this.rest.getQuestionThemeName().subscribe
    ((data: {
        result: any;
      }) => {
        // console.log('logging theme list content');
        this.ListByThemeName = data.result;
        // console.log(this.ListByThemeName);
      }
    );

  }
  saveUserResponse() {
    // console.log('Inside saveUserResponse in question component caller');
    this.rest.saveUserResponse(this.userResponse).subscribe(res => {
          // console.log(res);
          if (res.status === 'success') {
            this.router.navigate(['feedback']);
          }
        }, (err) => {
          // console.log(err);
        });
  }
  //
  getCountyList() {
    // console.log('Inside getCountyList in question component caller');
    this.countyList = [];
    this.rest.getCountyList().subscribe
    (
      (data: {
        result: any;
      }) => {
        // console.log('logging Country data');
        // console.log(data);
        this.countyList = data;
      }
      );
  }
  //
  getQuestionByTheme() {
    // console.log('Inside getQuestionByTheme in question component caller');
    this.questionListByTheme = [];
    this.rest.getQuestionByTheme().subscribe
    ((data: {}) => {
        // console.log('logging question content');
        // console.log(data);
        this.questionListByTheme = data;
        this.totalQuestions = this.questionListByTheme.length;
        // console.log(this.totalQuestions);
        // console.log('logging question language to be shown');
        // console.log(this.selectedLanguage);
        this.userQuestionController(this.questionListByTheme);
      }
    );
  }

  userQuestionController(allQuestions) {
    // This function prepares the question controller after getting all fetched questions and arranging them in pages based on theme name
    for ( const eachQuestion of allQuestions) {
      // console.log(eachQuestion.mtee_question_level);
      // console.log(eachQuestion.mtee_question_id);
      // console.log(eachQuestion.mtee_question_theme_name);
      // console.log(eachQuestion.mtee_question_type_name);
      // console.log(eachQuestion.questionType);
      if (this.firstQuestionPage === '') {
        this.firstQuestionPage = eachQuestion.mtee_question_theme_name;
        // console.log('firstQuestionPage');
        // console.log(this.firstQuestionPage);
      }
      if (this.firstQuestionPage !== '' && this.prevQuestionPage === '') {
        this.prevQuestionPage = eachQuestion.mtee_question_theme_name;
        // console.log('prevQuestionPage');
        // console.log(this.prevQuestionPage);
      }
      if (this.prevQuestionPage !== '' && this.prevQuestionPage !== eachQuestion.mtee_question_theme_name) {
        this.nextQuestionPage = eachQuestion.mtee_question_theme_name;
        this.lastQuestionPage = eachQuestion.mtee_question_theme_name;
        // console.log('nextQuestionPage');
        // console.log(this.nextQuestionPage);
        this.questionsInPage.push(this.prevQuestionPage = this.questionListSelectedTheme.length);
        this.questionsAnswered.push(0);
        // console.log('questionsInPage ');
        // console.log(this.questionsInPage);
        // console.log('questionsAnswered ');
        // console.log(this.questionsAnswered);
        this.listTheme.push(this.prevQuestionPage = this.questionListSelectedTheme);
        this.questionListSelectedTheme = [];
        this.prevQuestionPage = '';
        // console.log(this.listTheme);
      }
      this.questionListSelectedTheme.push(eachQuestion);
    }
    // console.log('lastQuestionPage');
    // console.log(this.lastQuestionPage);
    this.listTheme.push(this.prevQuestionPage = this.questionListSelectedTheme);
    this.questionsAnswered.push(0);
    this.questionListSelectedTheme = [];
    this.prevQuestionPage = '';
    this.userResponse.start_time = new Date();
    // console.log(this.listTheme);
  }

  answeredQuestion(event: Event, questionId: string | number, questionAnswer: string | number, questionType: string | number, indexPage) {
    // console.log('Page No: ' + indexPage);
    if (this.answerModel[questionId] === undefined) {
      if ((indexPage === 2) && (this.scaleQuestionsHandled === false)) {
        // console.log('Calling fillScaleQuestions : ' );
        this.fillScaleQuestions();
      }
      let answeredQuestionForPage = this.questionsAnswered[indexPage];
      // console.log('Question Answered for page: ' + this.questionsAnswered[indexPage]);
      answeredQuestionForPage = answeredQuestionForPage + 1;
      this.questionsAnswered[indexPage] = answeredQuestionForPage;
      // console.log('Question Answered for page: ' + this.questionsAnswered[indexPage]);
    }
    //
    // console.log('Question and Answered for page: ' + this.questionsAnswered);
    // console.log(questionId);
    // console.log(questionAnswer);
    this.answerModel[questionId] = questionAnswer;
    // console.log(' Answered Model: ');
    // console.log( this.answerModel);
    this.calculateProgressbarValues(indexPage);
  }
  //
  calculateProgressbarValues(indexPage) {
    this.pageQuestionLength = 0;
    // tslint:disable-next-line:forin
    for (const x in this.answerModel) {
      // console.log('x:' + x);
      if (this.answerModel[x] !== undefined) {
       // console.log('pageQuestionLength Value Initial');
       // console.log(this.pageQuestionLength);
       this.pageQuestionLength = this.pageQuestionLength + 1;
       // console.log('pageQuestionLength Value After Calculating Increment');
       // console.log(this.pageQuestionLength);
       this.progress = this.pageQuestionLength;
       // console.log('Progress Value');
       // console.log(this.progress);
       this.progressBarValue = (this.progress / this.totalQuestions) * 100;
       // console.log('ProgressBarValue as percentage of total questions : ' + this.totalQuestions);
       // console.log(this.progressBarValue);
       // console.log('Page Question Length');
       // console.log(this.pageQuestionLength);
      }
    }
    // console.log('AnswerModel Content :');
    // console.log(this.answerModel);

  }
  //
  fillScaleQuestions() {
    // console.log('Inside fillScaleQuestions: ');
    // console.log(this.answerModel);
    this.scaleQuestionsHandled = true;
    this.answerModel.SC01 = 0;
    this.answerModel.SC02 = 0;
    this.answerModel.SC03 = 0;
    this.answerModel.SC04 = 0;
    this.answerModel.SC05 = 0;
    this.answerModel.SC06 = 0;
    this.answerModel.SC07 = 0;
    this.answerModel.SC08 = 0;
    this.answerModel.SC09 = 0;
    this.answerModel.SC10 = 0;
    this.answerModel.SC11 = 0;
    this.answerModel.SC12 = 0;
    this.answerModel.SC13 = 0;
    this.answerModel.SC14 = 0;
    this.answerModel.SC15 = 0;
    this.questionsAnswered[2] = 14;
    // console.log(this.answerModel);
  }
 //
 answeredQuestionCheckbox( questionId: string , questionAnswer: string, isChecked, indexPage) {
  // Logic to see if the question has been answered before. if not answered updating the count.
  // console.log('Page No: ' + indexPage);
  if (this.answerModel[questionId] === undefined) {
    let answeredQuestionForPage = this.questionsAnswered[indexPage];
    answeredQuestionForPage = answeredQuestionForPage + 1;
    this.questionsAnswered[indexPage] = answeredQuestionForPage;
    // console.log('Question Answered for page: ' + this.questionsAnswered);
  }
  // Handling for first checkbox
  if (questionId === 'MS') {
      if (isChecked) {
        this.checkboxes1.forEach((element) => {
          // console.log(element.nativeElement.classList);
          if (element.nativeElement.id === 'MS10') {
            if (element.nativeElement.classList.contains('mat-checkbox-checked')) {
              element.nativeElement.classList.remove('mat-checkbox-checked');
              this.answerModelCheckbox1 = [];
            }
          }
        });
        this.answerModelCheckbox1.push(questionAnswer);
        // console.log(this.answerModelCheckbox1);
        this.answerModel[questionId] = undefined;
        this.answerModel[questionId] = this.answerModelCheckbox1;
      } else {
        const index = this.answerModelCheckbox1.indexOf(questionAnswer);
        this.answerModelCheckbox1.splice(index, 1);
        this.answerModel[questionId] = undefined;
        this.answerModel[questionId] = this.answerModelCheckbox1;
      }
  }
  // Handling for second checkbox
  if (questionId === 'CBB') {
      if (isChecked) {
        this.checkboxes2.forEach((element) => {
          // console.log(element.nativeElement.classList);
          if (element.nativeElement.id === 'CBB5') {
            if (element.nativeElement.classList.contains('mat-checkbox-checked')) {
              element.nativeElement.classList.remove('mat-checkbox-checked');
              this.answerModelCheckbox2 = [];
            }
          }
        });
        this.answerModelCheckbox2.push(questionAnswer);
        // console.log(this.answerModelCheckbox2);
        this.answerModel[questionId] = undefined;
        this.answerModel[questionId] = this.answerModelCheckbox2;
      } else {
        const index = this.answerModelCheckbox2.indexOf(questionAnswer);
        this.answerModelCheckbox2.splice(index, 1);
        this.answerModel[questionId] = undefined;
        this.answerModel[questionId] = this.answerModelCheckbox2;
      }
  }
  // code to calculate progress bar value
  // this.pageQuestionLength = 0;
  this.calculateProgressbarValues(indexPage);
}
//
answeredQuestionCheckbox1( questionId: string , questionAnswer: string, isChecked, indexPage) {
  // Logic to see if the question has been answered before. if not answered updating the count.
  // console.log('Page No: ' + indexPage);
  if (this.answerModel[questionId] === undefined) {
    let answeredQuestionForPage = this.questionsAnswered[indexPage];
    answeredQuestionForPage = answeredQuestionForPage + 1;
    this.questionsAnswered[indexPage] = answeredQuestionForPage;
    // console.log('Question Answered for page: ' + this.questionsAnswered);
  }
  if ( questionId === 'MS') {
    this.checkboxes1.forEach((element) => {
      // console.log(element.nativeElement.classList);
      if (element.nativeElement.id !== 'MS10') {
              // console.log('Checking if Checked : ' + element.nativeElement.id);
              if (element.nativeElement.classList.contains('mat-checkbox-checked')) {
                element.nativeElement.classList.remove('mat-checkbox-checked');
                // console.log('Removed Checked');
              }
      } else {
       element.nativeElement.classList.add('mat-checkbox-checked');
      }
    });
    // console.log(this.answerModelCheckbox1);
    this.answerModelCheckbox1 = [];
    this.answerModelCheckbox1.push(questionAnswer);
    this.answerModel[questionId] = undefined;
    this.answerModel[questionId] = this.answerModelCheckbox1;
  }
  //
  if (questionId === 'CBB') {
    this.checkboxes2.forEach((element) => {
      // console.log(element.nativeElement.classList);
      if (element.nativeElement.classList.contains('mat-checkbox-checked')) {
        element.nativeElement.classList.remove('mat-checkbox-checked');
      }
    });
    this.answerModelCheckbox2 = [];
    this.answerModelCheckbox2.push(questionAnswer);
    this.answerModel[questionId] = undefined;
    this.answerModel[questionId] = this.answerModelCheckbox2;
  }
  // code to calculate progress bar value
  this.calculateProgressbarValues(indexPage);
}
  //
  //
  getProvienceList() {
    this.rest.getProvienceList().subscribe(
      (data: {}) => {
        this.provienceRecord = data;
        // tslint:disable-next-line:forin
        for (const x in data) {
          this.provienceList.push(data[x]);
        }
        this.provienceList.sort((a1, b1) => a1.localeCompare(b1));
      }
    );
  }
  //
  //
  onProvienceSelect(provience, questionID, questionType, index ) {
    this.selectedProvience = provience.value;
    this.municipalityListShow1 = [];
    // tslint:disable-next-line:forin
    for (const x in this.municipalities1) {
      const listedProvience = this.municipalities1[x].name_provience;
      if (listedProvience === this.selectedProvience) {
        this.municipalityListShow1.push(this.municipalities1[x].name_municipality);
        this.selectedProvienceCode = this.municipalities1[x].code_provience;
        // console.log('Provience Code for Selected Municipality : ' + this.selectedProvienceCode);
      }
    }
    this.municipalityListShow1.sort((a1, b1) => a1.localeCompare(b1));
    this.selectedProvienceNameAndCode = this.selectedProvience + ',' + this.selectedProvienceCode;
    // console.log('Provience Value for DB : ' + this.selectedProvience);
    this.answeredQuestion(event, questionID, this.selectedProvienceNameAndCode, questionType, index);
    // this.formReportRequest.provienceCode = this.selectedProvience;
  }

// getMunicipalityList

getMunicipalityList() {
  // console.log('Inside getMunicipalityList in question component caller');
  this.municipalityList1 = {};

  this.rest.getMunicipalityList().subscribe
  (
    (data: {}) => {
      // tslint:disable-next-line:forin
      for (const x in data) {
        // tslint:disable-next-line:one-variable-per-declaration
        const temp1: Municipality =
          {name_municipality: undefined,
            code_municipality: undefined,
            name_provience: undefined,
            code_provience: undefined};
        this.municipalityList1[data[x].mtee_municipality_name_1] = data[x].mtee_municipality_code;
        temp1.name_municipality = data[x].mtee_municipality_name_1;
        temp1.code_municipality = data[x].mtee_municipality_code;
        temp1.name_provience = data[x].mtee_county_provience_name_1;
        temp1.code_provience = data[x].mtee_county_provience_code;
        this.municipalityListShow1.push(data[x].mtee_municipality_name_1);
        this.municipalities1.push(temp1);
      }
      this.municipalities1.sort((a1, b1) => a1.name_municipality.localeCompare(b1.name_municipality));
      // console.log(this.municipalityList1);
      // console.log(this.municipalityListShow1);
    }
    );
}
// On municpilatySelect
onMunicipalitySelect(municipality, questionId, indexPage) {
  // Logic to see if the question has been answered before. if not answered updating the count.
  // console.log('Page No: ' + indexPage);
  if (this.answerModel[questionId] === undefined) {
    let answeredQuestionForPage = this.questionsAnswered[indexPage];
    answeredQuestionForPage = answeredQuestionForPage + 1;
    this.questionsAnswered[indexPage] = answeredQuestionForPage;
    // console.log('Question Answered for page: ' + this.questionsAnswered);
  }
  // console.log('---- municipality --: ', municipality.value);
  this.selectedMunicipalityToSave = municipality.value;
  // tslint:disable-next-line:forin
  for (const x in this.municipalities1) {
      const listedMunicipality = this.municipalities1[x].name_municipality;
      if (listedMunicipality === this.selectedMunicipalityToSave) {
        this.selectedMunicipalityCode = this.municipalities1[x].code_municipality;
        // console.log('Municipality Code for Selected Municipality : ' + this.selectedMunicipalityCode);
      }
  }
  this.selectedMunicipalityNameAndCode = this.selectedMunicipalityToSave + ',' + this.selectedMunicipalityCode;
  if (this.answerModel[questionId] === undefined) {
    this.answerModel.SPB = this.selectedMunicipalityNameAndCode;
  } else {
    this.answerModel.SPB = undefined;
    this.answerModel.SPB = this.selectedMunicipalityNameAndCode;
  }
  this.calculateProgressbarValues(indexPage);
}
//
radioChange(event: MatRadioChange) {
    if (event.value == "1") {
      this.hasCampaignCode = true;
      this.verifiedCampaign = false;
    }
    if (event.value == "0") {
      this.verifiedCampaign = true;
      this.hasCampaignCode = false;
    }
}
//
/* //
  openCampaignModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'campaignModal-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  //
  private getDismissReason(reason: any): string {
    this.submit();
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }*/
  //
  //
  searchcampaign () {
        console.log(' Inside searchcampaign ');
        this.saveCampaignCodeRequest.mtee_campaign_code = this.saveCampaignForm.value.mtee_campaign_code;
        console.log(this.saveCampaignCodeRequest);
        this.rest.searchCampaign(this.saveCampaignCodeRequest)
            .subscribe(data => {
              if ( data.status === 'success') {
                this.verifiedCampaign = true;
                this.hasCampaignCode = false;
                this.userResponse.mtee_campaign_code = this.saveCampaignCodeRequest.mtee_campaign_code;
                //this.submit();
              }
            });
  }
}
