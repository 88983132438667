<div class="appExitComponent" fxLayout="row" fxLayoutAlign="center initial">
  <div class="jumbotron col-md-10">
      <mat-expansion-panel  [expanded]="this.isPanelExpanded" [disabled]="this.isPanelDisabled== true">

        <mat-expansion-panel-header>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-9">
                <input matInput type="text" [(ngModel)]="reportKey" placeholder="Enter report key to generate report.">
              </div>
              <div>
                <button button mat-stroked-button color="primary" class="btn-sm" (click) = "verifyKey()">Verify</button>
              </div>
            </div>


          </div>
        </mat-expansion-panel-header>
        <mat-card *ngIf="showPanel">
          <form [formGroup]="formReport" name="formReport" role="form" id="formReport">
                          <div class="formReport" id="formReport">
                                 <div class="">
                                        <mat-form-field>
                                          <mat-label>Start date</mat-label>
                                          <input matInput [min]="minStartDate" [max]="maxStartDate" [matDatepicker]="picker1" (dateChange)="selectedStartDate($event)">
                                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                          <mat-datepicker touchUi="true" #picker1></mat-datepicker>
                                        </mat-form-field>
                                 </div>

                                 <div class="">
                                        <mat-form-field>
                                          <mat-label>End date</mat-label>
                                          <input matInput [min]="minEndDate" [max]="maxEndDate" [matDatepicker]="picker2" (dateChange)="selectedEndDate($event)">
                                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                          <mat-datepicker touchUi="true" #picker2></mat-datepicker>
                                        </mat-form-field>
                                 </div>

                                 <div class="">
                                        <div class="form-group">
                                          <input matInput type="text" id="mtee_campaign_code" formControlName="mtee_campaign_code" class="form-control" placeholder="Enter a unique Campaign Code" >
                                        </div>
                                 </div>
                          </div>
          </form>
          <button mat-button mat-raised-button color="accent" class="btn-block btn-outline-dark mat-button" (click) = "downloadData()">
                               Download
          </button>
          <p class = 'errorMessage'>{{this.errorMsg}}</p>
        </mat-card>
      </mat-expansion-panel>
  </div>
</div>
