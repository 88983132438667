import { Component, OnInit, Input } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

import { VERSION, ViewChild, AfterViewInit } from '@angular/core';
import {FormBuilder, FormGroup,  FormArray} from '@angular/forms';
import { WebserviceCallerService } from '../webservice-caller.service';
import { DataShareService } from '../data-share.service';
import {ActivatedRoute, Router} from '@angular/router';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {CookieService} from 'ngx-cookie-service';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// @ts-ignore
import {default as _rollupMoment, Moment} from 'moment';
import {ShowMessageService} from "../show-message.service";
import {MatSnackBar} from "@angular/material/snack-bar";

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class SignupComponent implements OnInit {
date = new FormControl(moment([]));
  minDate: Date;
  maxDate: Date;
  registerComponentItems: any = {};
  public selectedLanguage: string;
  public genderList = [0, 1, 2];
  formSignup: FormGroup;
  errorMsg: any = {};
  showUserFieldError: any = {};
  showPasswordFieldError: any = {};
  showEmailFieldError: any = {};
  successMsg: any = {};
  showDateSelectedField: boolean;
  public userSignupRequest = {
    mtee_user_id: undefined,
    mtee_user_yob: undefined,
    mtee_user_gender: 3,
    mtee_user_email: undefined,
    mtee_user_pwd: undefined,
    mtee_user_lang: undefined,
  };
  public signupResult: any;
  //
  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    this.userSignupRequest.mtee_user_yob = ctrlValue.year();
    // console.log('choosen' + this.userSignupRequest.mtee_user_yob);
    datepicker.close();
    this.showDateSelectedField = true;
  }
  //
  constructor(public rest: WebserviceCallerService,
              private route: ActivatedRoute,
              public cookieService: CookieService,
              private router: Router,
              private data: DataShareService,
              private formBuilder: FormBuilder,
              public showMessageService: ShowMessageService,
              public snackBar: MatSnackBar) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 70, 0, 1);
    this.maxDate = new Date(currentYear - 20, 11, 31);
  }

  ngOnInit() {
    this.data.registerPageStatic.subscribe(message => this.registerComponentItems = message);
    this.data.currentLanguage.subscribe(language => this.selectedLanguage = language);
    // console.log(this.selectedLanguage);
    // console.log(this.registerComponentItems);
    this.showDateSelectedField = false;
    this.formSignup = this.formBuilder.group({
      mtee_user_email: [null, Validators.compose([Validators.required, Validators.email])],
      mtee_user_id: [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(40)])],
      mtee_user_pwd: [null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(40)])]
    });
  }
//
  registerUser() {
    // console.log('Inside registerUser() ');
    // console.log(this.userSignupRequest);
    if ( this.userSignupRequest.mtee_user_yob === undefined) {
        this.errorMsg = this.showMessageService.getErrorMessageByCode('113');
        this.snackBar.open(this.errorMsg[this.selectedLanguage], '', {
          verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['errorSnackbar'], duration : 3000
        });
        return;
    }
    if ( this.userSignupRequest.mtee_user_gender === 3) {
        this.errorMsg = this.showMessageService.getErrorMessageByCode('114');
        this.snackBar.open(this.errorMsg[this.selectedLanguage], '', {
          verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['errorSnackbar'], duration : 3000
        });
        return;
    }
    if (   this.formSignup.value.mtee_user_email === null
          || this.formSignup.value.mtee_user_id === null
          || this.formSignup.value.mtee_user_pwd === null) {
      this.errorMsg = this.showMessageService.getErrorMessageByCode('120');
      this.snackBar.open(this.errorMsg[this.selectedLanguage], '', {
          verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['errorSnackbar'], duration : 3000
      });
      return;
    } else {
        this.userSignupRequest.mtee_user_email = this.formSignup.value.mtee_user_email;
        this.userSignupRequest.mtee_user_id = this.formSignup.value.mtee_user_id;
        this.userSignupRequest.mtee_user_lang = this.selectedLanguage;
        this.userSignupRequest.mtee_user_pwd = btoa(this.formSignup.value.mtee_user_pwd);
        this.rest.registerUser(this.userSignupRequest)
            .subscribe(data => {
              // console.log(' Data From Register User: ');
              // console.log(data);
              if ( data.status === 'success') {
                this.cookieService.set('UserId', '', undefined, '/', undefined, true);
                this.successMsg = data.result.mtee_message_text;
                this.snackBar.open(this.successMsg[this.selectedLanguage], '', {
                  verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['successSnackbar'], duration : 3000
                });
                setTimeout(() => {
                  // console.log('hide');
                  this.router.navigate(['']);
                  }, 2000);
              } else {
                this.errorMsg = data.result.mtee_message_text;
                this.snackBar.open(this.errorMsg[this.selectedLanguage], '', {
                  verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['errorSnackbar'], duration : 3000
                });
              }
            });
    }
  }
  redirectUser() {
    this.router.navigate(['login']);
  }
  resetForm() {
    // alert('here');
    this.formSignup.value.mtee_user_email = '';
    // alert(this.formSignup.value.mtee_user_email);
    this.formSignup.value.mtee_user_id = '';
    this.formSignup.value.mtee_user_yob = '';
    this.formSignup.value.mtee_user_gender = 0;
    this.formSignup.value.mtee_user_pwd = '';
  }
  //
  //
  showSuccessMessageSnackBar() {
    this.snackBar.open(this.successMsg[this.selectedLanguage], '', {
                  verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['successSnackbar'], duration : 3000
                });
  }
  //
  showErrorMessageSnackBar() {
    this.snackBar.open(this.errorMsg[this.selectedLanguage], '', {
        verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['errorSnackbar'], duration : 5000
      });
  }
  //
  getFormErrorTextUser() {
    // console.log('getFormErrorTextUser');
    this.showUserFieldError = this.showMessageService.getErrorMessageByCode('115');
    this.errorMsg = this.showUserFieldError;
    // console.log(this.errorMsg);
    return this.errorMsg[this.selectedLanguage];
  }
  //
  getFormErrorTextPassword() {
    // console.log('getFormErrorTextPassword');
    this.showPasswordFieldError = this.showMessageService.getErrorMessageByCode('116');
    this.errorMsg = this.showPasswordFieldError;
    // console.log(this.errorMsg);
    return this.errorMsg[this.selectedLanguage];
  }
  //
  getFormErrorTextEmail() {
    // console.log('getFormErrorTextEmail');
    this.showEmailFieldError = this.showMessageService.getErrorMessageByCode('103');
    this.errorMsg = this.showEmailFieldError;
    // console.log(this.errorMsg);
    return this.errorMsg[this.selectedLanguage];
  }

}
