 <div class="register-form jumbotron">

    <form id="formSignup" [formGroup]="formSignup">

      <h4 class="card-title text-center">{{registerComponentItems.registerTagline}}</h4>

      <div class="jumbotron-fluid">

        <div class="form-group">
          <div class="row">
            <div class="col-md-12">{{registerComponentItems.mtee_user_yob}}</div>

            <div class="col-md-6">
              <input [hidden]="!showDateSelectedField" matInput readonly class ="form-control date" (dateInput)="this.date=this.date.value" [matDatepicker]="dp"  [formControl]="date" [min]="minDate" [max]="maxDate" >
            </div>
            <div class="col-md-4 btn-outline-light">
              <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
              <mat-datepicker #dp touchUi
                            startView="multi-year"
                            (yearSelected)="chosenYearHandler($event, dp)" id="mtee_user_yob"
                            panelClass="example-year-picker jumbotron">
              </mat-datepicker>
            </div>
          </div>
        </div>

        <div class="form-group">
          <p class="gender text-muted justify-content-center">{{registerComponentItems.mtee_user_gender}}</p>
          <mat-radio-group aria-labelledby="example-radio-group-label" id="mtee_user_gender" >
            <mat-radio-button mat-button  type="button" class="example-radio-button"
                              *ngFor="let option of genderList ;index as j;" [value]="option"
                              (click)="userSignupRequest.mtee_user_gender=option">
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="form-group">
          <input matInput type="text" class="form-control" id="mtee_user_id" placeholder={{registerComponentItems.mtee_user_id}} required formControlName="mtee_user_id" >
        </div>

        <div class="form-group">
          <input matInput type="text" class="form-control" id="mtee_user_email" placeholder={{registerComponentItems.mtee_user_email_plchldr}} required formControlName="mtee_user_email" >
        </div>

        <div class="form-group">
          <input matInput type="password"  class="form-control" id="mtee_user_pwd" placeholder={{registerComponentItems.mtee_user_pwd_plchldr}} required formControlName="mtee_user_pwd" >
        </div>

        <div class="form-group">
          <button type="submit" class="btn action-button btn-block" (click)="registerUser()">{{registerComponentItems.mtee_register_form_btn}}</button>
        </div>

      </div>
      <mat-error *ngIf="formSignup.controls['mtee_user_id'].hasError('minlength')">
        {{getFormErrorTextUser()}}
      </mat-error>
      <mat-error *ngIf="formSignup.controls['mtee_user_pwd'].hasError('minlength')">
        {{getFormErrorTextPassword()}}
      </mat-error>
      <mat-error *ngIf="formSignup.controls['mtee_user_email'].invalid && this.formSignup.value.mtee_user_email !== null">
        {{getFormErrorTextEmail()}}
      </mat-error>
    </form>

</div>
