<div class="container-lg">

  <div class="jumbotron" style="background-color: rgb(255, 255, 255);">
    <div class="row">
      <div class="col-md-12">
        <h2>Settings Panel</h2>
      </div>
      <div class="col-md-12">
        <h2>Total Hits :  {{this.totalHits}}</h2>
      </div>
      <div class="col-md-12 jumbotron">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <br>
            <div class="btn btn-success" [routerLink]="['/reportByDate']">Generate Simple Report Between Dates</div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <br>
            <div class="btn btn-success" [routerLink]="['/municipalityChange']">Municipality and Provience</div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <br>
            <div class="btn btn-success" [routerLink]="['/campaign']">Create or Delete Campaign</div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <br>
            <div class="btn btn-success" [routerLink]="['/reportTypeNew']">Create New Report Type</div>
          </div>
        </div>
      </div>

      <div class="col-md-12 card ">
        <div class="row jumbotron">
          <div class="col-lg-3 col-md-3 col-sm-2 col-xs-2">
            <br>
            <div class="btn btn-dark" (click) = "refreshLanguage()">Refresh Language</div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-2 col-xs-2">
            <br>
            <div class="btn btn-dark" (click) = "refreshPageDisplay()">Refresh Page Display</div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-2 col-xs-2">
            <br>
            <div class="btn btn-dark" (click) = "refreshSuccessMessage()">Refresh Success Messages</div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-2 col-xs-2">
            <br>
            <div class="btn btn-dark" (click) = "refreshErrorMessage()">Refresh Error Messages</div>
          </div>
        </div>
      </div>

      <div class="col-md-12 card">
        <div class="row jumbotron">
          <div class="col-lg-3 col-md-3 col-sm-2 col-xs-2">
            <br>
            <div class="btn btn-warning" (click) = "refreshQuestions()">Refresh Questions</div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-2 col-xs-2">
            <br>
            <div class="btn btn-warning" (click) = "refreshThemeNames()">Refresh Theme Names</div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-2 col-xs-2">
            <br>
            <div class="btn btn-warning" (click) = "refreshLocation()">Refresh Locations</div>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
