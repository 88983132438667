 <div class="login-form jumbotron">

    <form id="formLogin" [formGroup]="formLogin">

      <h4 class="card-title text-center">{{loginComponentItems.mtee_login_tigline}}</h4>

      <div class="jumbotron-fluid">

        <div class="form-group">
          <input type="text" class="form-control" id="mtee_user_id" placeholder={{loginComponentItems.mtee_user_id_plchldr}}  formControlName="mtee_user_id" required>
        </div>

        <div class="form-group">
          <input type="password" class="form-control" id="mtee_user_pwd" placeholder={{loginComponentItems.mtee_user_pwd_plchldr}} formControlName="mtee_user_pwd" required>
        </div>

        <div class="form-group">
          <button type="submit" class="btn action-button btn-block" [disabled]="!formLogin.valid" (click)="loginUser()">{{loginComponentItems.mtee_login_btn}}</button>
        </div>

      </div>

      <div class="clearfix">
        <a [routerLink]="['/forgetpassword']" class="pull-right LinkButton">{{loginComponentItems.mtee_forgot_pwd_btn}}</a>
      </div>
      <mat-error *ngIf="formLogin.controls['mtee_user_id'].hasError('minlength')">
        {{getFormErrorTextUser()}}
      </mat-error>
      <mat-error *ngIf="formLogin.controls['mtee_user_pwd'].hasError('minlength')">
        {{getFormErrorTextPassword()}}
      </mat-error>
    </form>

    <div class="text-center">
      <button type="button" class="btn btn-lg action-button-secondary btn-outline-dark "  (click)="registerUserClicked()">{{navComponentItems.register}}</button>
    </div>

</div>
