import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WebserviceCallerService } from '../webservice-caller.service';
import {DataShareService} from '../data-share.service';
import {CookieService} from 'ngx-cookie-service';
import {ShowMessageService} from '../show-message.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  resetPasswordComponentItems: any = {};
  formReset: FormGroup;
  errorMsg: any = {};
  successMsg: any = {};
  showPasswordFieldError: any = {};
  public userRecoveryRequest = {
    password: undefined,
    resettoken: undefined,
  };
  public userResetRequest = {
    resettoken: undefined,
  };
  public selectedLanguage: string;
  //
  constructor( private formBuilder: FormBuilder,
               private activatedRoute: ActivatedRoute,
               private router: Router,
               public rest: WebserviceCallerService,
               private dataBus: DataShareService,
               private cookieService: CookieService,
               public showMessageService: ShowMessageService,
               public snackBar: MatSnackBar) {}
  //
  openSnackBar(message: string, action: string) {
       this.snackBar.open(message, action, {
       verticalPosition: 'bottom',
       horizontalPosition: 'left',
       panelClass: ['custom-snackbar']
     });
  }
  //
  ngOnInit() {
    // console.log('Inside Reset Password :::::::::');
    // this.selectedLanguage = 'Finnish';
    this.formReset = this.formBuilder.group({
      password: [null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(40)])],
      cnfpassword: [null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(40)])]
    });
    this.dataBus.resetPageStatic.subscribe(message => this.resetPasswordComponentItems = message);
    this.userRecoveryRequest.resettoken = this.activatedRoute.snapshot.queryParamMap.get('reset');
    // console.log(this.userRecoveryRequest.resettoken);
    this.userResetRequest.resettoken = this.userRecoveryRequest.resettoken;
    setTimeout(()=>{
    // console.log('Delaying for others functions to execute: ');
    if ( this.userRecoveryRequest.resettoken !== undefined) {
      this.rest.resetPassword(this.userResetRequest)
        .subscribe(data => {
          //console.log(data);
          if ( data.status === 'success') {
            //console.log('Verified');
            //console.log(data.result['mtee_user_lang']);
            this.selectedLanguage = data.result['mtee_user_lang'];
            this.dataBus.changeLanguage(this.selectedLanguage);
            this.successMsg = this.showMessageService.getSuccessMessageByCode('909');
            // console.log('Token verified:');
            // console.log(this.successMsg[this.selectedLanguage]);
            this.snackBar.open(this.successMsg[this.selectedLanguage], '...', {
                  verticalPosition: 'bottom',
                  horizontalPosition: 'center',
                  panelClass: ['successSnackbar'],
                  duration: 2000
                });
          } else {
            this.errorMsg = this.showMessageService.getErrorMessageByCode('107');
            this.snackBar.open(this.successMsg[this.selectedLanguage], '...', {
                  verticalPosition: 'bottom',
                  horizontalPosition: 'center',
                  panelClass: ['errorSnackbar'],
                  duration: 2000
                });
          }
        });
    }
    },1000);
  }
  //
  resetPassword() {
    if ( this.formReset.value.password === this.formReset.value.cnfpassword) {
      this.userRecoveryRequest.password = btoa(this.formReset.value.password);
      this.rest.updatePassword(this.userRecoveryRequest)
        .subscribe(data => {
          if ( data.status === 'success') {
            this.successMsg = this.showMessageService.getSuccessMessageByCode('901');
            this.snackBar.open(this.successMsg[this.selectedLanguage], '...', {
                  verticalPosition: 'bottom',
                  horizontalPosition: 'center',
                  panelClass: ['successSnackbar'],
                  duration: 2000
                });
                setTimeout(()=>{this.clearMemoryAndRefresh();},3000);
          } else {
            this.errorMsg = this.showMessageService.getErrorMessageByCode('109');
            this.snackBar.open(this.successMsg[this.selectedLanguage], '...', {
                  verticalPosition: 'bottom',
                  horizontalPosition: 'center',
                  panelClass: ['errorSnackbar'],
                  duration: 4000
                });
          }
        });
    } else {
      this.errorMsg = this.showMessageService.getErrorMessageByCode('118');
      this.snackBar.open(this.successMsg[this.selectedLanguage], '...', {
                  verticalPosition: 'bottom',
                  horizontalPosition: 'center',
                  panelClass: ['errorSnackbar'],
                  duration: 2000
      });
    }
  }
  //
  clearMemoryAndRefresh() {
    // this.cookieService.set('UserId', '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '/', undefined, true, "None");
    // this.cookieService.set('showFeedback', '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '/', undefined, true, "None");
    window.localStorage.clear();
    this.cookieService.delete('UserId');
    this.cookieService.delete('showFeedback');
    this.cookieService.deleteAll();
    this.successMsg = this.showMessageService.getSuccessMessageByCode('908');
    this.snackBar.open(this.successMsg[this.selectedLanguage], '', {
                  verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['successSnackbar'], duration : 2000
                });
    this.router.navigate(['']);
  }
  //
  //
  getFormErrorTextPassword() {
    // console.log('getFormErrorTextPassword');
    this.showPasswordFieldError = this.showMessageService.getErrorMessageByCode('116');
    this.errorMsg = this.showPasswordFieldError;
    // console.log(this.errorMsg);
    return this.errorMsg[this.selectedLanguage];
  }
}
