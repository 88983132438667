import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WebserviceCallerService} from '../webservice-caller.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataShareService} from '../data-share.service';
import {CookieService} from 'ngx-cookie-service';
import {ShowMessageService} from '../show-message.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatAccordion} from '@angular/material/expansion';
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material/datepicker';

export interface CampaignStructure {
  mtee_campaign_code: string;
  mtee_campaign_type: string;
  mtee_campaign_description: string;
  mtee_campaign_start_date: Date;
  mtee_campaign_end_date: Date;
  mtee_campaign_status: string;
}

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent implements OnInit {
  @ViewChild (MatAccordion) accordion: MatAccordion;
  //////////////////////////





  //////////////////////////////////
  // define page forms here
  public createCampaignForm: FormGroup;
  public searchCampaignForm: FormGroup;
  public editCampaignForm: FormGroup;

  // define show message variables here
  public editCampaignData : CampaignStructure = {
                mtee_campaign_code: '',
                mtee_campaign_type: '',
                mtee_campaign_description: '',
                mtee_campaign_start_date: new Date(),
                mtee_campaign_end_date: new Date(),
                mtee_campaign_status: ''
                }
  errorMsg: any = {};
  successMsg: any = {};
  minDate: Date;
  maxDate: Date;
  startDate =  new Date();
  endDate = new Date();
  panelOpenState = false;
  editCampaignFlag = false;
  // define api request structures here
  public searchRequest = {
    mtee_campaign_code: undefined,
  };
  public createCampaignCodeRequest = {
    mtee_campaign_code: undefined,
    mtee_campaign_type: undefined,
    mtee_campaign_description: undefined,
    mtee_campaign_start_date: undefined,
    mtee_campaign_end_date: undefined,
    mtee_user_id: undefined
  };
  public searchCampaignCodeRequest = {
    mtee_campaign_code: undefined
  };
  public editCampaignCodeRequest = {
    mtee_campaign_code: undefined,
    mtee_campaign_type: undefined,
    mtee_campaign_description: undefined,
    mtee_campaign_start_date: undefined,
    mtee_campaign_end_date: undefined,
    mtee_campaign_status: undefined,
    mtee_user_id: undefined
  };
  // define component specific variables here
  userLoginDetails: any = {};
  public selectedLanguage: string;
  //
  constructor(public rest: WebserviceCallerService,
              private route: ActivatedRoute,
              private router: Router,
              private dataBus: DataShareService,
              private formBuilder: FormBuilder,
              public cookieService: CookieService,
              public showMessageService: ShowMessageService,
              public snackBar: MatSnackBar) {
                   // Set the minimum to today and December 31st a year in the future.
                   const currentYear = new Date().getFullYear();
                   this.minDate = new Date();
                   this.maxDate = new Date(currentYear + 1, 11, 31);
                   this.createCampaignForm = this.formBuilder.group({
                          mtee_campaign_code: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(30)])],
                          mtee_campaign_type: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(30)])],
                          mtee_campaign_description: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(30)])]
                          });
                   this.searchCampaignForm = this.formBuilder.group({
                          mtee_campaign_code_search: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(30)])]
                          });
                   this.editCampaignForm = this.formBuilder.group({
                          mtee_campaign_code_edit: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(30)])],
                          mtee_campaign_type_edit: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(30)])],
                          mtee_campaign_description_edit: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(30)])],
                          mtee_campaign_start_date_edit: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(8)])],
                          mtee_campaign_end_date_edit: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(30)])],
                          mtee_campaign_status_edit: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(30)])]
                          });
              }
  // ********************************************************************************
  ngOnInit(): void {
  this.dataBus.currentLanguage.subscribe(language => this.selectedLanguage = language);
  this.dataBus.userLoginResult.subscribe(message => this.userLoginDetails = message);
  }
  //
  //
  selectedStartDate(event: MatDatepickerInputEvent<Date>) {
    this.startDate = new Date(event.value);
    this.endDate.setHours(0, 0, 0 );
    console.log('---------------- : ' + this.startDate);
    this.createCampaignCodeRequest.mtee_campaign_start_date = this.startDate;
  }
  //
  selectedEndDate(event: MatDatepickerInputEvent<Date>) {
    this.endDate = new Date(event.value);
    this.endDate.setHours(23, 59, 59 );
    console.log('---------------- : ' + this.endDate);
    this.createCampaignCodeRequest.mtee_campaign_end_date = this.endDate;
  }
  //
  //
  selectedStartDateEdit(event: MatDatepickerInputEvent<Date>) {
    this.startDate = new Date(event.value);
    this.endDate.setHours(0, 0, 0 );
    console.log('---------------- : ' + this.startDate);
    this.editCampaignCodeRequest.mtee_campaign_start_date = this.startDate;
  }
  //
  selectedEndDateEdit(event: MatDatepickerInputEvent<Date>) {
    this.endDate = new Date(event.value);
    this.endDate.setHours(23, 59, 59 );
    console.log('---------------- : ' + this.endDate);
    this.editCampaignCodeRequest.mtee_campaign_end_date = this.endDate;
  }
  //
  //
  createcampaign () {
        // console.log(' Inside createcampaign ');
        this.createCampaignCodeRequest.mtee_campaign_code = this.createCampaignForm.value.mtee_campaign_code;
        this.createCampaignCodeRequest.mtee_campaign_type = this.createCampaignForm.value.mtee_campaign_type;
        this.createCampaignCodeRequest.mtee_campaign_description = this.createCampaignForm.value.mtee_campaign_description;
        this.createCampaignCodeRequest.mtee_user_id = localStorage.getItem('UserId');
        console.log(this.createCampaignCodeRequest);
        this.rest.createCampaign(this.createCampaignCodeRequest)
            .subscribe(data => {
              if ( data.status === 'success') {
                this.snackBar.open(data.result, '', {
                  verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['successSnackbar'], duration : 3000
                });
              } else {
                this.snackBar.open(data.result, '', {
                  verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['errorSnackbar'], duration : 3000
                });
              }
            });
  }
  //
  searchcampaign () {
        console.log(' Inside searchcampaign ');
        this.searchCampaignCodeRequest.mtee_campaign_code = this.searchCampaignForm.value.mtee_campaign_code_search;
        console.log(this.searchCampaignCodeRequest);
        this.rest.searchCampaign(this.searchCampaignCodeRequest)
            .subscribe(data => {
              if ( data.status === 'success') {
                this.editCampaignFlag = true;
                // Set Values
                this.editCampaignForm.controls["mtee_campaign_code_edit"].setValue(data.result['mtee_campaign_code']);
                this.editCampaignForm.controls["mtee_campaign_type_edit"].setValue(data.result['mtee_campaign_type']);
                this.editCampaignForm.controls["mtee_campaign_description_edit"].setValue(data.result['mtee_campaign_description']);
                this.editCampaignForm.controls["mtee_campaign_start_date_edit"].setValue(data.result['mtee_campaign_start_date']);
                this.editCampaignForm.controls["mtee_campaign_end_date_edit"].setValue(data.result['mtee_campaign_end_date']);
                this.editCampaignForm.controls["mtee_campaign_status_edit"].setValue(data.result['mtee_campaign_status']);
                this.editCampaignCodeRequest.mtee_campaign_code = data.result['mtee_campaign_code'];
                this.editCampaignCodeRequest.mtee_campaign_type = data.result['mtee_campaign_type'];
                this.editCampaignCodeRequest.mtee_campaign_description = data.result['mtee_campaign_description'];
                this.editCampaignCodeRequest.mtee_campaign_start_date = data.result['mtee_campaign_start_date'];
                this.editCampaignCodeRequest.mtee_campaign_end_date = data.result['mtee_campaign_end_date'];
                this.editCampaignCodeRequest.mtee_campaign_status = data.result['mtee_campaign_status'];
                console.log(this.editCampaignCodeRequest);
              } else {
                this.snackBar.open(data.result, '', {
                  verticalPosition: 'top', horizontalPosition: 'center', panelClass: ['errorSnackbar'], duration : 3000
                });
              }
            });
  }
  //
  editcampaign () {
        console.log(' Inside editcampaign ');
        this.editCampaignData = this.editCampaignForm.value;
        this.editCampaignCodeRequest.mtee_campaign_code = this.editCampaignForm.value.mtee_campaign_code_edit;
        this.editCampaignCodeRequest.mtee_campaign_type = this.editCampaignForm.value.mtee_campaign_type_edit;
        this.editCampaignCodeRequest.mtee_campaign_description = this.editCampaignForm.value.mtee_campaign_description_edit;
        this.editCampaignCodeRequest.mtee_campaign_start_date = this.editCampaignForm.value.mtee_campaign_start_date_edit;
        this.editCampaignCodeRequest.mtee_campaign_end_date = this.editCampaignForm.value.mtee_campaign_end_date_edit;
        this.editCampaignCodeRequest.mtee_campaign_status = this.editCampaignForm.value.mtee_campaign_status_edit;
        this.editCampaignCodeRequest.mtee_user_id = localStorage.getItem('UserId');
        console.log(this.editCampaignCodeRequest);
        this.rest.editCampaign(this.editCampaignCodeRequest)
            .subscribe(data => {
              if ( data.status === 'success') {
                this.editCampaignFlag = false;
                this.snackBar.open('Edited', '', {
                  verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['successSnackbar'], duration : 3000
                });
              } else {
                this.snackBar.open(data.result, '', {
                  verticalPosition: 'bottom', horizontalPosition: 'center', panelClass: ['errorSnackbar'], duration : 3000
                });
              }
            });
  }

}
