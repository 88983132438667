<div class="container-lg">


  <h1></h1>

  <h3></h3>

  <!--<mat-card-actions class="action-buttons">
       <button  routerLink="/register" routerLinkActive="active" mat-button><mat-icon>thumb_up_alt</mat-icon> {{homeComponentItems.startbutton}}</button>
     </mat-card-actions>-->

  <div class="jumbotron" style="background-color: rgb(255, 255, 255);">
    <div class="row">
      <div class="col-md-12">
        <h2>{{infoComponentItems.mtee_app_info_header}}</h2>
      </div>
      <div class="col-md-7">
        <p class="mb-2 p-3 text-justify">{{infoComponentItems.mtee_app_infoText1}}</p>
      </div>
      <div class="col-md-5">
        <img class="img-fluid float-left" src="./assets/images/mtee_slideshow_02.jpg" alt="EUMTEE">
      </div>
      <hr class="my-4">

      <div class="col-md-5">
       <!-- <img class="img-fluid" src="./assets/images/lut_university_LBM.jpg"> -->
        <img class="img-fluid" src="./assets/images/people_lut.jpg">
      </div>
      <div class="col-md-7">
        <p>
          {{infoComponentItems.mtee_app_infoText2}}
        </p>
        <p>{{infoComponentItems.mtee_app_infoText3}}</p>
        <p>{{infoComponentItems.mtee_app_infoContact1}}</p>
        <p>{{infoComponentItems.mtee_app_infoContact2}}</p>
        <p>{{infoComponentItems.mtee_app_infoContact3}}</p>
        <p>{{infoComponentItems.mtee_app_infoDataSecurity}}</p>
      </div>
      <div class="col-md-12">
<br>
        <div class="btn btn-success">{{infoComponentItems.mtee_app_info_datasecurity_btn}}</div>

      </div>

    </div>
  </div>

</div>