import { Component, OnInit } from '@angular/core';
import {DataShareService} from '../data-share.service';
import {CookieService} from 'ngx-cookie-service';
import {WebserviceCallerService} from '../webservice-caller.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  infoComponentItems: any = {};
  totalHits: {};
  constructor(private data: DataShareService, private  cookieService: CookieService, private rest: WebserviceCallerService) { }

  ngOnInit() {
    this.data.infoPageStatic.subscribe(message => this.infoComponentItems = message);
    this.getUserAccessDetails();
    /*if ( this.cookieService.get('UserId') === '*') {
      this.cookieService.set('UserId', '', undefined, '/', undefined, true);
    }*/
  }
  //
  getUserAccessDetails() {
    // console.log('Inside getUserAccessDetails in setting component caller');
    this.rest.getUserAccessDetails().subscribe
    (
      (data: {}) => {
        // console.log('logging getUserAccessDetails data');
        // console.log(data);
        this.totalHits = data;
      }
      );
  }
  //
  refreshLanguage() {
  this.rest.getAllLanguageRefreshed().subscribe
    ((data: {}) => {});
  }
  //
  refreshPageDisplay() {
  this.rest.getAllStaticContentRefreshed().subscribe
    ((data: {}) => {});
  }
  //
  refreshSuccessMessage() {
  this.rest.getSuccessMessageListRefreshed().subscribe
    ((data: {}) => {});
  }
  //
  refreshErrorMessage() {
  this.rest.getErrorListRefreshed().subscribe
    ((data: {}) => {});
  }
  //
  refreshQuestions() {
  this.rest.getQuestionByThemeRefreshed().subscribe
    ((data: {}) => {});
  }
  //
  refreshThemeNames() {
  this.rest.getQuestionThemeNameRefreshed().subscribe
    ((data: {}) => {});
  }
  //
  refreshLocation() {
        this.rest.getCountyListRefreshed().subscribe
          ((data: {}) => {});
        this.rest.getYsimittariCountyListRefreshed().subscribe
          ((data: {}) => {});
  }
}
